import React, { FC, ReactNode, Ref, useState } from 'react';
import PostPreview, {
  PostPreviewContent,
  PostPreviewVariant,
  VideoPlayerRef,
} from '@kontentino/kontentino-post-previews';
import { faComment } from '@fortawesome/pro-regular-svg-icons/faComment';
import { faFlagSwallowtail } from '@fortawesome/pro-regular-svg-icons/faFlagSwallowtail';
import { faText } from '@fortawesome/pro-regular-svg-icons/faText';
import { TYPE } from '@kontentino/kontentino-constants/Pages';
import { SUBTYPE } from '@kontentino/kontentino-constants/Posts';
import PostPreviewContentBox from 'app/components/postPreviewWithVariants/PostPreviewContentBox';
import PostPreviewLayoutSwitch from './PostPreviewLayoutSwitch';
import clsx from 'clsx';
import UserPermissionGate from 'components/UserPermissionGate';
import { UserPermission } from 'constants/userPermission';
import DownloadPostAssetsDropdown from './mediaAttachmentsDownload/DownloadPostAssetsDropdown';
import { useTranslation } from 'react-i18next';
import { Button, Icon, TextLink } from '@kontentino/ui';
import { formatDateTimeUTCToLocalTimezone } from 'app/utils/date';
type Props = {
  content: PostPreviewContent;
  use12hourFormat: boolean;
  availableVariants?: PostPreviewVariant[];
  variant?: PostPreviewVariant;
  onVariantChange?(variant: PostPreviewVariant): void;
  videoPlayerRef?: Ref<VideoPlayerRef>;
  videoPlayerId?: string;
  dataCy?: {
    postText?: string;
    wrapper?: string;
  };
  alertMessage?: ReactNode;
  onEditDateTimeClick?: () => void;
  isDownloadAssetsHidden?: boolean;
  revisionInfo?: {
    createdAt: string;
    id?: number;
  };
};

const PostPreviewWithVariants: FC<Props> = ({
  content,
  variant,
  use12hourFormat,
  onVariantChange,
  videoPlayerRef,
  videoPlayerId,
  dataCy,
  alertMessage,
  availableVariants,
  onEditDateTimeClick,
  isDownloadAssetsHidden,
  revisionInfo,
}) => {
  const { t } = useTranslation();
  const [ownVariant, setOwnVariant] = useState<PostPreviewVariant>('desktop');
  const activeVariant = variant ?? ownVariant;
  const onActiveVariantChange = onVariantChange
    ? onVariantChange
    : setOwnVariant;

  const showDownloadAssets =
    !isDownloadAssetsHidden &&
    content?.id &&
    content?.attachments.cards.some((card) => card.medium);

  return (
    <div className="tw-w-full">
      <div className="tw-flex tw-flex-col tw-items-center">
        <div
          className={clsx('tw-mb-3 tw-flex tw-w-full tw-items-center', {
            'tw-justify-between': showDownloadAssets || revisionInfo,
            'tw-justify-center': !showDownloadAssets && !revisionInfo,
          })}
        >
          <PostPreviewLayoutSwitch
            onVariantChange={onActiveVariantChange}
            activeVariant={activeVariant}
            availableVariants={availableVariants}
          />

          {(revisionInfo || showDownloadAssets) && (
            <div className="tw-flex tw-items-center tw-gap-4 tw-rounded-md tw-bg-grayscale-10 tw-px-2 tw-py-[6px]">
              {revisionInfo && (
                <div className="tw-flex tw-items-center tw-gap-2 ">
                  <Icon icon={faFlagSwallowtail} size={'sm'} />
                  <p className="tw-text-sm tw-font-semibold">
                    {t('versionFrom')}{' '}
                    {formatDateTimeUTCToLocalTimezone(revisionInfo.createdAt)}
                  </p>
                </div>
              )}
              {showDownloadAssets && content.id && (
                <DownloadPostAssetsDropdown
                  id={content.id}
                  revisionId={revisionInfo?.id}
                >
                  <Button variant="plain" size="small">
                    <TextLink content-cy="post-preview_download-post-assets">
                      <span>{t('downloadPostAssets')}</span>
                    </TextLink>
                  </Button>
                </DownloadPostAssetsDropdown>
              )}
            </div>
          )}
        </div>

        {alertMessage && <div className="tw-my-4">{alertMessage}</div>}
        <div
          className={clsx(
            'tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center tw-gap-2 tw-overflow-hidden',
            {
              'tw-min-w-[320px] tw-max-w-[500px]': activeVariant === 'desktop',
              'tw-min-w-[200px] tw-max-w-[340px]': activeVariant === 'mobile',
            },
          )}
          data-cy={dataCy?.wrapper}
          translate="no"
        >
          <UserPermissionGate
            scopes={UserPermission.POST_EDIT_DATE_TIME}
            deniedProps={{ onEditDateTimeClick: undefined }}
          >
            <PostPreview
              content={content}
              variant={activeVariant}
              videoPlayer={{
                ref: videoPlayerRef,
                id: videoPlayerId,
                crossOrigin: 'anonymous',
              }}
              dataCy={dataCy}
              use12hourFormat={use12hourFormat}
              onEditDateTimeClick={onEditDateTimeClick}
            />
          </UserPermissionGate>

          {!!content.text &&
            content.page.type === TYPE.INSTAGRAM &&
            content.subtype === SUBTYPE.INSTAGRAM_STORY && (
              <PostPreviewContentBox
                title="Post text"
                content={content.text}
                icon={faText}
                data-cy="post-preview-additional-content_post-text"
                data-name="post-preview-additional-content_post-text"
              />
            )}
          {!!content.attachments.firstComment && (
            <PostPreviewContentBox
              title="First comment"
              content={content.attachments.firstComment.text}
              icon={faComment}
              textClassName="tw-whitespace-break-spaces"
              data-cy="post-preview-additional-content_post-first-comment"
              data-name="post-preview-additional-content_post-first-comment"
              className="tw-mt-3"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PostPreviewWithVariants;
