import React, { FC } from 'react';
import { useParams } from 'react-router';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import { PostPreviewVariant } from '@kontentino/kontentino-post-previews';
import style from 'components/publicPreviews/post/PublicPostPreview.module.scss';
import Link from 'components/shared/Link';
import { PublicUtils } from 'components/publicPreviews/publicUtils';
import { CommentSection } from 'components/publicPreviews/post/CommentSection';
import PreviewExpired from 'components/publicPreviews/PreviewExpired';
import { useQuery } from 'react-query';
import PostApi from 'api/post';
import PostPreviewWithVariants from 'app/components/PostPreviewWithVariants';
import { BrowserStorage } from 'utils/browserStorage';
import { BrandLogo, Icon } from '@kontentino/ui';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons/faCircleInfo';
import DemoBadge from 'app/components/DemoBadge';
import WorkflowStatusBadge from 'app/components/workflowStatus/WorkflowStatusBadge';
import PostCommunicationProvider from 'app/modules/posts/modules/post/components/postCommunication/PostCommunicationProvider';
import Skeleton from 'react-loading-skeleton';
import getSocialMediaTypeForBrandLogo from 'app/utils/getSocialMediaTypeForBrandLogo';
type Props = {
  token: string;
};

export const PublicPostPreview: FC<Props> = ({ token }) => {
  const { postId } = useParams<{ postId: string }>();
  const { data, isLoading } = useQuery(
    ['publicPostPreview', postId, token],
    () => PostApi.publicPreview(postId!, token),
    {
      onSuccess(data) {
        PublicUtils.setSharedData(data.user);
      },
    },
  );

  const [variant, setVariant] = useLocalStorage<PostPreviewVariant>(
    BrowserStorage.keys.Posts.PreviewVariant,
    'desktop',
  );

  return (
    <div className={style.main} data-cy="public-post-preview">
      <div className={style.info}>
        {isLoading && <Skeleton width="100%" height="40px" />}
        {data && (
          <>
            <div className="tw-flex tw-items-center tw-gap-2 tw-text-base tw-font-semibold">
              <BrandLogo
                src={data.post.page.logo_src ?? undefined}
                name={data.post.page.name}
                size={24}
                socialMediaType={getSocialMediaTypeForBrandLogo(
                  data.post.page.type,
                )}
                iconSize={12}
              />
              <div
                className="tw-relative tw-max-w-xl tw-truncate"
                translate="no"
                data-cy="post-header-profile-name"
              >
                {data.post.page.name}
              </div>
              {data.post.page.is_demo && <DemoBadge />}
              <WorkflowStatusBadge status={data.post.status} />
            </div>
            <span
              className={style.sharedBy}
              data-cy="public-post-preview_sharedBy"
            >
              {data.user.name} has shared this preview with you.
            </span>
            <Link
              to="https://www.kontentino.com"
              data-name="public-link-more-about-kont"
              data-cy="public-link-more-about-kont"
              className="tw-flex tw-items-center"
            >
              <Icon icon={faCircleInfo} className={style.icon} />
              <span className={style.text}>Learn more about Kontentino</span>
            </Link>
          </>
        )}
      </div>
      {data && (
        <div className={style.wrapper}>
          <div className={style.column}>
            <PostPreviewWithVariants
              content={{ ...data.post, text: data.post.raw_text }}
              variant={variant}
              use12hourFormat={!PublicUtils.publicMock.user.userMeridianTime}
              onVariantChange={setVariant}
              isDownloadAssetsHidden
            />
          </div>
          <div className={style.column}>
            <div className={style.comments}>
              <PostCommunicationProvider
                postId={Number(postId)}
                user={PublicUtils.publicMock.user}
                enableRespondToApprovalRequest={true}
                disableCommunicationActions
              >
                <CommentSection token={token} />
              </PostCommunicationProvider>
            </div>
          </div>
        </div>
      )}
      {!isLoading && !data && <PreviewExpired />}
    </div>
  );
};

export default PublicPostPreview;
