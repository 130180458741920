import { createContext, FC, useContext } from 'react';
import { useBrandHubFilterState } from 'app/modules/brandHub/hooks/useBrandHubFilterState';
import { useBrandHubGenerateCreativesConfig } from 'app/modules/brandHub/hooks/useBrandHubGenerateCreativesConfig';

type BrandHubContextType = {} & ReturnType<typeof useBrandHubFilterState> &
  ReturnType<typeof useBrandHubGenerateCreativesConfig>;

const BrandHubContext = createContext<BrandHubContextType | undefined>(
  undefined,
);

type Props = {
  children: React.ReactNode;
};

export const BrandHubProvider: FC<Props> = ({ children }) => {
  const filterState = useBrandHubFilterState();
  const creativesConfig = useBrandHubGenerateCreativesConfig();

  return (
    <BrandHubContext.Provider value={{ ...filterState, ...creativesConfig }}>
      {children}
    </BrandHubContext.Provider>
  );
};

export const useBrandHubContext = () => {
  const context = useContext(BrandHubContext);

  if (context === undefined) {
    throw new Error(
      'useBrandHubContext must be used within a BrandHubProvider',
    );
  }

  return context;
};
