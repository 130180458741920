import React from 'react';
import { DropdownMenu, Icon } from '@kontentino/ui';
import { faArrowDownToLine } from '@fortawesome/pro-regular-svg-icons/faArrowDownToLine';
import { useTranslation } from 'react-i18next';
import MediaCountLabel from './components/MediaCountLabel';
import { useActions } from 'app/hooks/useActions';
import { useQuery } from 'react-query';
import { PostDetail } from 'types/PostDetail';
import { queryKey } from 'constants/queryKey';
import PostApi from 'api/post';
import { getMediaDownloadSizes } from 'app/modules/posts/utils/getMediaDownloadSizes';
type Props = {
  id: number;
  revisionId?: number;
  children: React.ReactNode;
};

const DownloadPostAssetsDropdown: React.FC<Props> = ({
  id,
  children,
  revisionId,
}) => {
  const { data } = useQuery<PostDetail>(queryKey.postDetail(id), () =>
    PostApi.detail(id),
  );

  const mediaDownloadSizes = data?.type ? getMediaDownloadSizes(data.type) : [];
  const { t } = useTranslation();
  const { postActions } = useActions('post-detail');

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>{children}</DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content className="tw-z-[1001] tw-w-56">
          <MediaCountLabel postId={id} />
          {mediaDownloadSizes.map((size, index) => (
            <DropdownMenu.Item
              key={`download-options-${index}`}
              onClick={() =>
                postActions.downloadAssets.perform({
                  id,
                  revisionId,
                  size: size.value,
                })
              }
            >
              <Icon icon={faArrowDownToLine}></Icon>
              {t(size.tKey)}
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default DownloadPostAssetsDropdown;
