import { v4 } from 'uuid';
import { useState } from 'react';

export type GenerateCreativeConfig = {
  id: string;
  goal: string;
  brandProfileId: number;
  pageType: number;
};

export function useBrandHubGenerateCreativesConfig() {
  const [creativesConfig, setCreativesConfig] = useState<
    GenerateCreativeConfig[]
  >([]);

  function addConfig(newCreative: Omit<GenerateCreativeConfig, 'id'>) {
    setCreativesConfig((prevState) => [
      {
        id: v4(),
        goal: newCreative.goal,
        brandProfileId: newCreative.brandProfileId,
        pageType: newCreative.pageType,
      },
      ...prevState,
    ]);
  }

  function removeConfig(id: string) {
    setCreativesConfig((prevState) =>
      prevState.filter((creative) => creative.id !== id),
    );
  }

  return {
    creativesConfig,
    creativesConfigActions: {
      addConfig,
      removeConfig,
    },
  };
}
